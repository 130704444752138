<template>
  <div class="buttonWrapper">
    <!-- 列表操作按钮 -->
    <div style="width: 100%; height: 30px">
      <span>
        <ul class="top-btns pull-right">
          <li v-for="(item, idx) in btnList" :key="idx" class="btn pull-left">
            <span class="btnName" @click="handleClick(item.action)">{{
              item.label
            }}</span>
          </li>
        </ul>
      </span>
      <el-button
        type="primary createBtn pull-right"
        size="mini"
        style="padding: 7px 12px; margin-top: 1px; border: 0"
        @click="add"
        >{{ createBtnName }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { BatchLeadScore } from "@/views/marketList/api.js";
// import SearchTable from '@/components/Form/search-table.vue';
import * as CommonObjApi from "../../../api";
import * as request from "@/views/marketList/api.js";
import { GetMailchimpGetMailChimpPermission } from "@/views/marketList/api.js";

export default {
  name: "buttonWrapper",
  components: {
  },
  data() {
    return {
      seaoption: [],
      importshow: false,
      createBtnName: this.$i18n.t("label.new"), // 新建按钮名称
      //群发邮件
      batchEmail: "",
      ownerName: "",
      ownerId: "",
      fieldId: "ffe100000001265aWyi8", // 所有人 信息写死
      relevantObjId: "user",
      relevantPrefix: "005",
      showSearchTable: false,
      input: "",
      delDialogVisible: false,
      getchangeowners: [],
      showMoreBtn: false,
      batchAddDialog: false,
      batchAddDialogTitle: this.$i18n.t("label.object.batchadd", {
        objName: this.tabName,
      }),
      isShowThree: true,
      isSendMail: false,
      transferOwnerDialog: false,
      btnList: [
        {
          // 批量删除
          label: this.$i18n.t("label.batchDelete"),
          action: "delete",
          name: "Batch Delete",
        },
      ],
      buttonList: [
        {
          // 添加标签
          label: this.$i18n.t("label.tag.addtags"),
          action: "addTag",
          name: "addTag",
        },
        {
          // 批量新增
          label: this.$i18n.t("label.batchadd"),
          action: "batchAdding",
          name: "Batch Added",
        },
        {
          // 批量转移
          label: this.$i18n.t("label.batchtransfer"),
          action: "batchRemove",
          name: "Batch Transfer",
        },
        {
          // 批量打分
          label: this.$i18n.t("Lead_Homepage_Tools_BatchScoring"),
          action: "batchScoring",
          name: "batchScoring",
        },
        {
          // 批量删除
          label: this.$i18n.t("label.batchDelete"),
          action: "batchDelete",
          name: "Batch Delete",
        },
        {
          //群发邮件
          label: this.$i18n.t("label.emailobject.groupemail"),
          action: "GroupEmail",
          name: "Group Email",
        },
        {
          // 批量发送短信
          label: this.$i18n.t("label.sendsms.batch"),
          action: "groupSMS",
          name: "Group SMS",
        },
        {
          //查重合并
          label: this.$i18n.t("label.mergeRechecking"),
          action: "checkDuplicationAndMerge",
          name: "mergeRechecking",
        },
        {
          // 撞单分析
          label: "撞单分析",
          action: "collisionAnalysis",
          name: "myzdfx",
        },
        {
          // 合并
          label: this.$i18n.t("label.checkfield.merge"),
          action: "merge",
          name: "Merge",
        },
        {
          // 领取
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Get",
          name: "get",
        },
        {
          // 分配
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Assign",
          name: "assign",
        },
        {
          // 退回
          label: this.$i18n.t("vue_label_pool_return"),
          action: "returnPool",
          name: "Return Pool",
        },
        {
          // 回收
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Recycle",
          name: "recycle",
        },
        {
          // 废弃
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Discard",
          name: "discard",
        },
        {
          // 恢复
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Recover",
          name: "recover",
        },
        {
          // 转移
          // label: this.$i18n.t('label.checkfield.merge'),
          action: "Transfer",
          name: "transfer",
        },
      ],
      val: "",
      num: 5,
      selectNum: 0,
      count: 5,
      restaurants: [],
      recordTypeList: [],
      recordTypeListId: "",
      objId: this.$route.params.objId,
      objectApi: this.$route.params.objectApi,
      state: "",
      stateone: "",
      datalist: [], //批量增长
      fieldlist: [],
      index: "",
      ids: "",
      // prefix: this.$route.params.prefix, // 对象前缀
      checkedList: "",
      scoringVisible: false, // 批量打分
      str: "", //删除看板数据的所有id
      /**
       * 公海池部分参数
       */
      clientPoolDialogIsShow_Recover_Recycle_Get: false, //公海池右边操作按钮对话框【恢复，回收，领取】
      clientPoolDialogIsShow_Back_Discard: false, //公海池右边操作按钮对话框【退回，废弃】
      clientPoolDialogIsShow_Transfer: false, //公海池右边操作按钮对话框【转移】
      clientPoolDialogIsShow_Assign: false, //公海池右边操作按钮对话框【分配】
      clientPoolDialogIsShow_Delete: false, //公海池右边操作按钮对话框【批量删除】
      // 操作指令对象
      clientPoolOprationObject: {
        oprationDirctive: "", //操作指令,当前对话框所附带的事件类型（每一个 action 按钮的事件名称 ）
        oprationDirctiveName: "", //操作的指令名称
        // tableCheckedObjectArr
      },
      // 退回、废弃表单(选中的参数)
      Back_Discard_Form: {
        firstSelect: "",
        secondDesc: "",
        backtopoolId: "", //退回公海池id
      },
      Back_Discard_FormOptionList: [], //退回、废弃的下拉选项列表
      Back_Discard_FormOptionListLoading: false, //退回、废弃的下拉选项列表请求接口时loading
      Transfer_FormOptionList: [], //转移公海池时查询可转入的公海池列表
      Transfer_Form: { firstSelect: "" }, //转移的表单
      Assign_FormOptionList: [], //分配的选项列表
      Assign_Form: { firstSelect: "" }, //分配的表单
      isMailChimp: null, //MailChimp按钮展示权限
      delContent: "", //删除提示语
    };
  },
  mounted() {
    this.selMarketsea();
  },
  methods: {
    //退回公海池
    async marketseaReturnAndRecovery() {
      return;
    },
    //获取对象下公海池
    async selMarketsea(val) {
      let params = {
        objid: val,
      };
      let res = await request.selMarketsea(params);
      this.seaoption = res.data;
    },
    // 显示按钮
    showBtn(idx) {
      return Number(idx) < 4;
    },
    // 在更多中显示按钮
    showMoreBtns(idx) {
      return Number(idx) > 3;
    },
    // 新建
    add() {
      this.$emit("add");
    },
    open(ssr) {
      this.$message(ssr);
    },
    // 批量删除
    batchdelete() {
      if (this.selectNum == 0) {
        this.$confirm(
          this.$i18n.t("lable.opppdt.selPdt.leastone"),
          this.$i18n.t("label.prompt"),
          {
            type: "warning",
          }
        );
      } else {
        if (
          this.objectApi === "CloudccProject" ||
          this.objectApi === "cloudccMilestone" ||
          this.objectApi === "CloudccProblem" ||
          this.objectApi === "cloudccTask" ||
          this.objectApi === "cloudccSubtask"
        ) {
            this.delContent = this.$i18n.t("front-project-v1-batch-deletion-confirmation-information")
        } else {
          this.delContent = this.$i18n.t(
            "vue_label_commonobjects_view_notice_deletecertification",
            {
              selectNum: this.selectNum,
            }
          );
        }
        this.delDialogVisible = true;
      }
    },
    // 批量删除确认
    confirmDel() {
      var param = {
        ids: this.ids,
        prefix: this.prefix,
      };
      CommonObjApi.batchDelete(param).then((res) => {
        if (
          res.data.deleteerrorlist === undefined ||
          res.data.deleteerrorlist === null
        ) {
          this.$message.success(res.returnInfo);
          this.$parent.refreshAfterBatchDelete();
        } else {
          let returnInfo = "";
          res.data.deleteerrorlist.forEach((deleteerror) => {
            returnInfo += this.$i18n.t(
              "vue_label_commonobjects_view_notice_deletefailreason",
              [deleteerror.id, deleteerror.errormessage]
            );
          });
          this.$message.error(returnInfo);
          if (res.data.deleteerrorlist.length !== param.ids.split(",").length) {
            this.$parent.refreshAfterBatchDelete();
          }
        }
        this.val = "";
        this.delDialogVisible = false;
      });
    },
    // 点击空白部分关闭弹窗（公海池开启）
    handleClose(done) {
      done();
      this.delDialogVisible = false;
    },
    // 公海池弹窗主动关闭
    clientPoolCancle() {
      // 弹窗隐藏
      this.clientPoolDialogIsShow_Recover_Recycle_Get = false;
      this.clientPoolDialogIsShow_Back_Discard = false;
      this.clientPoolDialogIsShow_Transfer = false;
      this.clientPoolDialogIsShow_Assign = false;
      this.clientPoolDialogIsShow_Delete = false;
    },
    // 公海池的对话框动画执行完毕，自动清除事件指令【交互优化】
    clientPoolClosed() {
      // 清除公海池的弹窗保留的事件名称
      this.clientPoolOprationObject = {};
    },
    // 刷新页面
    refresh() {
      this.$emit("refresh");
    },
    // 处理按钮点击事件
    handleClick(action) {
      this.$emit(action);
    },
    //批量打分
    scoringBtn() {
      BatchLeadScore({ viewId: this.viewId }).then((res) => {
        if (res.result && res.returnCode === "1") {
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("vue_label_lead_score_successsful"),
          });
        } else {
          this.$message.error(res.returnInfo);
        }
      });
      this.scoringVisible = false;
    },
    // 保存批量新增
    saveBatchAdd() {
      this.$refs.batchAdd.savedata();
    },
    // 关闭批量新增
    closeBatchAddDialog() {
      this.batchAddDialog = false;
    },
    //查找所有人
    remoteSearch() {
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    changeSelect(row) {
      this.ownerName = row.data.name;
      this.ownerId = row.data.id;
      this.showSearchTable = false;
    },
    handleSelect() {},
    handleIconClick() {},
    /**
     * 公海池部分事件
     */
    // 公海池弹窗二次确认事件调用（发射）
    // 回收、领取、恢复
    RecycleGetRecoverClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject //操作数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    // 退回公海池，废弃
    async Back_DiscardClientPoolConfirmEvent() {
      let id = this.selectedList.map((item) => item.id).join(",");
      let params = {
        marketseaid: this.Back_Discard_Form.backtopoolId,
        objectApi: this.objectApi,
        thyyms: this.Back_Discard_Form.firstSelect,
        id,
        type: "retuen",
        rejectreason: this.Back_Discard_Form.secondDesc,
      };
      let res = await request.marketseaReturnAndRecovery(params);
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
      if (res.result == true) {
        this.Back_Discard_Form.backtopoolId = "";
        this.Back_Discard_Form.firstSelect = "";
        this.Back_Discard_Form.secondDesc = "";
        this.$emit("change", true);
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // 转移
    TransferClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject, //操作数据
        this.Transfer_Form //表单数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    // 分配
    AssignClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject, //操作数据
        this.Assign_Form //表单数据
      );

      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    // 批量删除
    DeleteClientPoolConfirmEvent() {
      this.$emit(
        "clientPoolRightBtnClick",
        this.clientPoolOprationObject //操作数据
      );
      // 调用取消事件，关闭弹窗
      this.clientPoolCancle();
    },
    // 公海池勾选的客户数量检测
    checkCheckedCount() {
      if (this.tableCheckedObjectArr.length === 0) {
        this.$message.warning(this.$i18n.t("lable.opppdt.selPdt.leastone")); //请选择至少1条数据
        return false;
      } else if (this.tableCheckedObjectArr.length > 200) {
        this.$message.warning(
          this.$i18n.t("vue_label_commonobjects_view_up_to_data")
        ); //最多选择200条数据
        return false;
      } else {
        return true;
      }
    },
    // 废弃、退回 下拉操作列表获取
    Back_Discard_FormOptionListFun(status) {
      /**
                 ffeleadmarketsea05  潜在客户 退回原因
                 ffeaccountmarketsea5  客户

                 ffeleadmarketsea07 潜在 客户废弃
                 ffeaccountmarketsea7 客户废弃
                 */
      let fieldId = null;
      if (this.objId === "lead") {
        //  如果是潜在客户
        if (status === "Back") {
          // 如果是 【退回操作】
          fieldId = "ffeleadmarketsea05";
        } else if (status === "Discard") {
          // 如果是 【废弃操作】
          fieldId = "ffeleadmarketsea07";
        }
      } else if (this.objId === "account") {
        //  如果是客户
        if (status === "Back") {
          // 如果是 【退回操作】
          fieldId = "ffeaccountmarketsea5";
        } else if (status === "Discard") {
          // 如果是 【废弃操作】
          fieldId = "ffeaccountmarketsea7";
        }
      }
      // 加载中
      this.Back_Discard_FormOptionListLoading = true;
      // 获取选项下拉列表
      CommonObjApi.getSelectValue({
        fieldId,
      }).then((res) => {
        res.result && (this.Back_Discard_FormOptionList = res.data);
        // 加载中
        this.Back_Discard_FormOptionListLoading = false;
      });
    },
    // 公海池每次打开某个对话框，清除之前表单记录
    clearClientPoolFormDataFun() {
      // 退回、废弃表单(选中的参数)
      this.Back_Discard_Form.firstSelect = "";
      this.Back_Discard_Form.secondDesc = "";
      //转移的表单
      this.Transfer_Form.firstSelect = "";
      //分配的表单
      this.Assign_Form.firstSelect = "";
    },
    async GetMailchimpGetMailChimpPermissions() {
      //获取Mailchimp权限
      let res = await GetMailchimpGetMailChimpPermission();
      if (
        res.data &&
        (this.objectApi === "Lead" || this.objectApi === "cloudcclead" ||this.objectApi === "Contact")
      ) {
        this.isMailChimp = true;
      } else {
        this.isMailChimp = false;
      }

      // if (res.result) {
      //   if (
      //     res.data &&
      //     (this.objectApi === 'Lead' || this.objectApi === 'Contact')
      //   ) {
      //     this.isMailChimp = true
      //   } else {
      //     this.isMailChimp = false
      //   }
      // }
    },
    mailchimp() {
      this.$emit("mailchimp");
    },
  },
  watch: {
    showButtons: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.init();
        });
      },
    },
    $route(nval) {
      this.objectApi = nval.params.objectApi;
      // this.prefix = nval.params.prefix;
      this.$nextTick(() => {
        // this.recordtype();
      });
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    clientPoolCheckDialogTitle() {
      if (this.objId === "lead") {
        //  如果是潜在客户
        return this.$i18n.t("vue_label_normal_lead_sf");
      } else if (this.objId === "account") {
        //  如果是客户
        return this.$i18n.t("vue_label_normal_account_sf");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.textItem {
  font-size: 12px;
  color: #666;
  cursor: pointer;
  line-height: 25px;
  padding: 0 12px;
  margin: 0 -12px;

  &:hover {
    background: #f3f2f2;
    color: #006dcc;
  }
}

.buttonWrapper {
  position: relative;

  .boxCard {
    width: 120px;
    position: absolute;
    right: 0;
    z-index: 10;

    ::v-deep .el-card__body {
      padding: 12px;
    }
  }

  .createBtn {
    height: 28px;
  }

  .top-btns {
    border: 1px solid #dedcda;
    border-radius: 3px;
    height: 30px;
    line-height: 27px;
    background: #ffff;
    margin-left: 10px;
    li:last-child {
      border-right: 0;
      span:first-child {
        // border-right: 1px solid #dedcda;
      }
    }
    li {
      font-size: 12px;
      cursor: pointer;
      border-right: 1px solid #dedcda;
      line-height: 29px;
      .btnName {
        padding: 0 12px;
        height: 100%;
        display: inline-block;
        cursor: pointer;
        color: #006dcc;
        &:hover {
          color: #006dcc;
        }
      }

      .dividingLine {
        font-size: 16px;
        color: #c8c6c6;
      }

      .icon {
        font-size: 18px;
        color: #888888;
        padding: 3px 5px 0 5px;
      }
    }
  }

  .line-two-btn {
    .btn {
      padding: 0;
      height: 29px;
      border: 1px solid #c8c6c6;
    }

    img {
      width: 18px;
      height: 18px;
    }

    .group-btn {
      margin-top: -15px;
    }

    .in-left {
      margin-left: 10px;
    }

    .in-right {
      margin-right: 10px;
    }
  }
}
.tank {
  height: 480px;
  width: 90%;
  background: rgba(255, 255, 255, 1);
  z-index: 6;
  margin: 100px auto 0;
}
.title {
  height: 53px;
  line-height: 53px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  color: rgba(109, 114, 120, 1);
  font-size: 18px;
}
.btnp {
  font-weight: bold;
  font-size: 17px;
}
.btnx {
  font-size: 16px;
}
.tankone {
  width: 30%;
  min-height: 359px;
  background: rgba(255, 255, 255, 1);
  margin: 110px auto;
}
.tanktwo {
  width: 90%;
  min-height: 694px;
  background: rgba(255, 255, 255, 1);
  margin: 110px auto;
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input__icon {
  height: auto;
}
.al {
  margin: 0 24px;
  width: auto;
  overflow: auto;
  height: 259px;
  border: 1px solid rgba(190, 225, 255, 1);
}
.masstransfer {
  height: 18px;
  font-size: 18px;
  margin-top: 34px;
  margin-left: 35px;
  font-weight: bold;
  color: #666666;
  display: inline-block;
  line-height: 18px;
}
/*.al::-webkit-scrollbar-track {
      !*display: none;*!
      width: 5px;
    }*/
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
::v-deep .el-input__icon {
  margin-top: -4px;
}
.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;
  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}
ul {
  margin: 0;
}
.selectnum {
  height: 20px;
  font-size: 12px;
  margin-top: -20px;
  font-weight: 400;
  color: #666666;
  padding-bottom: 22px;
}
.checkboxs {
  margin-left: 6px;
  font-size: 12px;
  line-height: 28px;
}
.spancolor {
  color: #fa6400;
}
.spans {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-top: 12px;
}
.remoteBtn {
  cursor: pointer;

  // &:hover{
  //   color:blue;
  // }
}

.clientPoolDialogIsShow_Back_Discard-margin {
  margin: 5px 0;
}
.clientPoolDialogIsShow_Transfer-box-style {
  margin: 20px 0;
}
</style>
